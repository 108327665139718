import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

function SignUpSection() {
  return (
    <section class=" md:pt-4 pb-4 mb-10">
      <div class="rounded-2xl py-0  flex justify-center align-center shadow-lg bg-white w-5/6 mx-auto  bg-gradient-to-r via-sky-50 to-cyan-50 from-purple-50 ctaBoxShadow">
        <div class="text-center text-white w-4/5 m-auto py-4 " >
          <span class="text-2xl md:text-4xl text-[#182430]  font-semibold pt-4 mt-3 Inter">
            Get started with
          </span>
          <span
            class="bg-gradient-to-r from-[#7A76FC] to-[#61A4EB] text-transparent bg-clip-text capitalize
text-2xl md:text-6xl font-semibold flex Inter justify-center "
style={{textAlign:"center",alignItems:"center"}}
          >
            {" "}
            <img src="/img/logos/logo.png" class="w-12 sm:w-36 " /> Neatprocess{" "}
          </span>
          <span class="text-2xl md:text-4xl  text-[#182430]  font-semibold Inter">
            today.
          </span>
          <p class="font-normal text-md sm:text-lg pt-4 mt-4 text-[#314359] Inter">
            Make your Customers happy and reduce the burden on your employees
          </p>
          <div class="pt-8">
            <Button
              class="lg:inline inline-flex mx-auto lg:mx-0 items-center 
    font-semibold border border-solid border-blue-400 py-3 px-5 focus:outline-none focus:ring-4 
    focus:ring-blue-300 hover:bg-blue-700 rounded-full text-gradient
      mt-4 lg:mt-0"
      data-tally-open="mOAaPK" 
            >
              Schedule Demo
              <FontAwesomeIcon
                style={{ color: "blue !important", fontSize: "medium" }}
                icon={"arrow-right"}
              ></FontAwesomeIcon>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUpSection;
