import React, { useEffect, useRef } from "react";
import { BigPlayButton, ControlBar, PlayToggle, Player } from "video-react";

import { Box } from "@mui/material";
import "video-react/dist/video-react.css";
import ReactPlayer from "react-player";

export default function CustomReactPlayer({
  videoSrc,
  autoplay,
  setProgress,
  nextItem,
  height,
  ...props
}) {
  const { player } = useRef(null);
  const config={
    youtube: {
      playerVars: { showinfo: 0,autoplay:1,loop:1 }
    },
    file:{
      autoplay:1
    }

  }

  const handleProgress = (data) =>{

    if(setProgress){
      setProgress(data.played*100)
    }
    if(data.played>=0.95 && nextItem){
      nextItem()
    }
    
  }

  return (
    <Box id="video-1"  {...props}>
      <ReactPlayer
        url={videoSrc}
        ref={player}
        width={"100%"}
        height={height}
        // controls
        playing={true}
        onProgress={handleProgress}
        progressInterval={100}
        muted={true} 
        loop
        // onReady={() => {
        //   if (player) player.play();
        // }}
        // config={config}
      />
    </Box>
  );
}
