import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import {
  faArrowRight,
  faCross,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

function ProblemSection() {
  return (
    <div>
      <section
        id={"problem-section"}
        class="mb-24   w-10/12 sm:w-4/5  bg-white mx-auto sm:mx-auto "
      >
        <div class="text-center md:pt-16 ">
          <span
            class="badgeBoxShadow capitalize rounded-2xl text-xs sm:text-lg  font-medium leading-loose 
tracking-widest px-4 py-2 badgeGradientText text-transparent bg-clip-text  Inter"
          >
            Problems with Current Softwares
          </span>
          <h2 class="font-bold text-2xl md:text-3xl lg:text-4xl text-iso-gray-dark pt-3 mt-2  Inter">
            Resolution of claims could take{" "}          <span class="font-bold text-3xl md:text-4xl lg:text-4xl text-gradient Inter">
            Months
          </span>
          </h2>

          <p class="font-normal text-md sm:text-lg  text-iso-gray-light pt-4 mt-0  Inter">
            Tired of the cumbersome processes involved in claim resolution?
          </p>
        </div>
        <div class="mt-8 mt-12 text-lg ">
          <div class="grid grid-cols-1  md:grid-cols-1  lg:grid-cols-1 xl:grid-cols-2 gap-8">
            <div class="rounded-2xl border border-[#e5e7eb] border-solid  ">
              <Box
                container
                sx={{ padding: "2rem", alignItems: "center", height: "100%" }}
                spacing={1}
                className={"grid grid-cols-2 gap-2 md:gap-2"}
              >
                <Box
                  
                  xs={12}
                  sx={{ fontWeight: "400 !important" }}
                  className={"col-span-2 text-xl sm:text-2xl text-center "}
                >
                  Complex Workflows
                </Box>

                <Box
                  item
                  xs={6}
                  sx={{
                    
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  className={" hidden sm:flex col-span-2 sm:col-span-1 m-1  gap-2"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      height={"1rem"}
                      icon={faXmark}
                      // style={{ marginTop: "0.2rem" }}
                    ></FontAwesomeIcon>

                    <Typography marginLeft={"0.5rem"} fontSize={"small"}>
                      Navigating the complex workflows can feel like a maze.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      height={"1rem"}
                      // style={{ marginTop: "0.2rem" }}
                      icon={faXmark}
                    ></FontAwesomeIcon>

                    <Typography marginLeft={"0.5rem"} fontSize={"small"}>
                      Traditional softwares struggle to accommodate this,
                      leading to inefficiencies.{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  item
                  xs={6}
                  sx={{
                    // height: "80%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={"hidden sm:flex col-span-2 sm:col-span-1 m-1 gap-2  sm:w-6/6"}
                >
                  <Box
                    className={
                      "rounded-2xl border border-[#e5e7eb] border-solid md:col-span-2 featureGradient1"
                    }
                    sx={{
                      height: "100%",
                      alignContent: "center",
                      
                      display:"flex",
                      justifyContent:"center",
                      width:"100%"
                    }}

                  >
                    <img
                      src={"/img/initial/complex-workflow4.png"}
                      style={{ }}
                      className="w-7/12 sm:w-7/12"
                    />
                  </Box>
                </Box>
              </Box>
            </div>
            {/* Second Card */}
            <div class="rounded-2xl border border-[#e5e7eb] border-solid  ">
              <Box
                container
                sx={{ padding: "2rem", alignItems: "center", height: "100%" }}
                spacing={1}
                className={"grid grid-cols-2 gap-2 md:gap-2"}
              >
                <Box
                  
                  xs={12}
                  sx={{ fontWeight: "400 !important",  }}
                  className={"col-span-2 text-xl sm:text-2xl text-center "}
                >
                  Intricate Decision Trees
                </Box>

                <Box
                  item
                  xs={6}
                  sx={{
                    
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  className={"hidden sm:flex col-span-2 sm:col-span-1 m-1 gap-2"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      height={"1rem"}
                      icon={faXmark}
                      // style={{ marginTop: "0.2rem" }}
                    ></FontAwesomeIcon>

                    <Typography marginLeft={"0.5rem"} fontSize={"small"}>
                      Claim resolution involves a series of decision points.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      height={"1rem"}
                       // style={{ marginTop: "0.2rem" }}
                      icon={faXmark}
                    ></FontAwesomeIcon>

                    <Typography marginLeft={"0.5rem"} fontSize={"small"}>
                      Current softwares lack in
                      navigating the decision trees.{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  item
                  xs={6}
                  sx={{
                    // height: "80%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={"hidden sm:flex col-span-2 sm:col-span-1 m-1 gap-2  sm:w-6/6"}
                >
                  <Box
                    className={
                      "rounded-2xl border border-[#e5e7eb] border-solid md:col-span-2 featureGradient1"
                    }
                    sx={{ height: "100%", alignContent: "center" }}
                  >
                    <img
                      src={"/img/initial/complex-decision-trees2.png"}
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Box>
              </Box>
            </div>

            {/* Third Card */}
            <div class="rounded-2xl border border-[#e5e7eb] border-solid  ">
              <Box
                container
                sx={{ padding: "2rem", alignItems: "center", height: "100%" }}
                spacing={1}
                className={"grid grid-cols-2 gap-2 md:gap-2"}
              >
                <Box
                  
                  xs={12}
                  sx={{ fontWeight: "400 !important",  }}
                  className={"col-span-2 text-xl sm:text-2xl text-center "}
                >
                 Multiple Communication Channels
                </Box>

                <Box
                  item
                  xs={6}
                  sx={{
                    
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  className={"hidden sm:flex col-span-2 sm:col-span-1 m-1 gap-2"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      height={"1rem"}
                      icon={faXmark}
                      // style={{ marginTop: "0.2rem" }}
                    ></FontAwesomeIcon>

                    <Typography marginLeft={"0.5rem"} fontSize={"small"}>
                      Claim resolution depends on seamless communication
                      between multiple parties.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      height={"1rem"}
                      // style={{ marginTop: "0.2rem" }}
                      icon={faXmark}
                    ></FontAwesomeIcon>

                    <Typography marginLeft={"0.5rem"} fontSize={"small"}>
                      Outdated systems hinder the process from accelerating.{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  item
                  xs={6}
                  sx={{
                    // height: "80%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={"hidden sm:flex col-span-2 sm:col-span-1 m-1 gap-2  sm:w-6/6"}
                >
                  <Box
                    className={
                      "rounded-2xl border border-[#e5e7eb] border-solid md:col-span-2 featureGradient1 p-6"
                    }
                    sx={{ height: "100%", alignContent: "center" }}
                  >
                    <img
                      src={"/img/initial/communication-channel1.png"}
                      className=" w-full"
                    />
                  </Box>
                </Box>
              </Box>
            </div>

            {/* Fourth Card */}
            <div class="rounded-2xl border border-[#e5e7eb] border-solid  ">
              <Box
                container
                sx={{ padding: "2rem", alignItems: "center", height: "100%" }}
                spacing={1}
                className={"grid grid-cols-2 gap-2 md:gap-2"}
              >
                <Box
                  
                  xs={12}
                  sx={{ fontWeight: "400 !important",  }}
                  className={"col-span-2 text-xl sm:text-2xl text-center "}
                >
                  Limited Reporting Capabilities
                </Box>

                <Box
                  item
                  xs={6}
                  sx={{
                    
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  className={"hidden sm:flex col-span-2 sm:col-span-1 m-1 gap-2 "}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      height={"1rem"}
                      icon={faXmark}
                      // style={{ marginTop: "0.2rem" }}
                    ></FontAwesomeIcon>

                    <Typography marginLeft={"0.5rem"} fontSize={"small"}>
                      Current analytical dashboards require manual effort to
                      incorporate new charts.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      height={"1rem"}
                      // style={{ marginTop: "0.2rem" }}
                      icon={faXmark}
                    ></FontAwesomeIcon>

                    <Typography marginLeft={"0.5rem"} fontSize={"small"}>
                      They lack customizations and 
                      interactive data analysis{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  item
                  xs={6}
                  sx={{
                    // height: "80%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={"hidden sm:flex col-span-2 sm:col-span-1 m-1 gap-2  sm:w-6/6"}
                >
                  <Box
                    className={
                      "rounded-2xl border border-[#e5e7eb] border-solid md:col-span-2 featureGradient1"
                    }
                    sx={{ height: "100%", alignContent: "center" }}
                  >
                    <img
                      src={"/img/initial/dashboard1.png"}
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProblemSection;
