import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
} from "@material-ui/core";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
        padding: "1rem",
      },
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: 240,
    zIndex: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  policyItem: {
    cursor: "pointer",
    "&.active": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const Terms = () => {
  const classes = useStyles();
  const location = window.location.pathname;

  const policies = [
    { id: 1, title: "Security Policy", link: "/terms/security-policy.html",path:"/terms/security-policy" },
    { id: 2, title: "Privacy Policy", link: "/terms/privacy-policy.html",path:"/terms/privacy-policy" },
    { id: 3, title: "GDPR", link: "/terms/gdpr-policy.html",path:"/terms/gdpr-policy" },
  ];

  const initialPolicy = policies.find((policy) => policy.path === location);

  const [selectedPolicy, setSelectedPolicy] = React.useState(initialPolicy || policies[0]);

  const handlePolicyClick = (policy) => {
    window.location.href = policy.path;
    setSelectedPolicy(policy);
  };

  return (
    <div>
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          <a href="/" class="flex font-medium items-center company-logo">
            <img src="/img/logos/logo.png" class="w-12 md:w-14" />
            <Box className={"new-text-gradient  text-xl md:text-3xl"}>
              Neatprocess
            </Box>
          </a>

          <ListItem>
            <ListItemText>
              <Typography variant="h6">Terms and Policies</Typography>
            </ListItemText>
          </ListItem>
          {policies.map((policy) => (
            <ListItem
              key={policy.id}
              className={`${classes.policyItem} ${
                selectedPolicy.id === policy.id ? "active" : ""
              }`}
              onClick={() => handlePolicyClick(policy)}
            >
              <ListItemText primary={policy.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <iframe
        src={selectedPolicy.link}
        width="100%"
        style={{ height: "100vh" }}
      />
      
    </div>
    <Footer  />
    </div>
  );
};

export default Terms;
