import { Box } from "@mui/material";

import Header from "./header";
import HeroSection from "./heroSection";
import Features from "./features";
import ProblemSection from "./problemSection";
import ComponentsSection from "./componentsSection";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";
import SignUpSection from "./signUpSection";
import FaqSection from "./faqSection";
import Footer from "./footer";
import AnimateOnScroll from "./animateOnScroll";

function HomePage() {
  return (
    <Box>
      <ThemeProvider theme>
        <CssBaseline />
        {/* <Slide in={true} direction="up" mountOnEnter unmountOnExit timeout={500}>
          <Box>Hello how are you</Box>
        </Slide> */}
        <Header />
        <HeroSection />

        <AnimateOnScroll reappear={false}>
          <ProblemSection />
        </AnimateOnScroll>
        <AnimateOnScroll reappear={false}>
        <ComponentsSection />
        </AnimateOnScroll>
        <AnimateOnScroll reappear={false}>
        <Features />
        </AnimateOnScroll>
        <AnimateOnScroll reappear={false}>
        <FaqSection />
        </AnimateOnScroll>
        <AnimateOnScroll reappear={false}>
        <SignUpSection />
        </AnimateOnScroll>
        <Footer />
      </ThemeProvider>
    </Box>
  );
}

export default HomePage;
