import { Box, Button, Link } from "@mui/material";
import Features from './features';

function Header() {
  return (
    <header class="text-iso-gray pt-4">
      <nav class="border-gray-200 px-2 sm:px-4 py-2.5 rounded">
        <div class="flex flex-wrap items-center justify-center sm:justify-between">
          <a href="#" class="flex font-medium items-center text-gray-900">
            <img src="img/logos/logo.png" class="w-12 md:w-24" /><Box  className={"text-gradient text-2xl md:text-4xl"}>Neatprocess</Box>
          </a>
          {/* <div class="hidden sm:flex"> */}
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            class="hidden sm:inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <ul
            class="w-full lg:w-auto block lg:inline text-center lg:text-left flex flex-col  lg:flex-row space-y-4 space-x-0 
    lg:space-x-10 lg:space-y-0 flex-grow lg:grow-0 pt-4 lg:pt-0 hidden"
    style={{position:"absolute",left:"50%",transform:"translate(-50%,0)"}}
          >
                        <li class="block lg:inline">
              <Link class="text-grey font-semibold cursor-pointer Inter" href={"#component-section"}>
                Components
              </Link>
            </li>
            <li class="block lg:inline">
              <Link class="text-grey font-semibold cursor-pointer Inter" href={"#feature-section"}>
                Features
              </Link>
            </li>
            {/* <li class="block lg:inline">
              <Link class="text-black font-semibold cursor-pointer Inter" target="_blank" href={"https://neatprocess.notion.site/Introducing-AI-Assistant-0a4d5f65ca7b4da581c8a753c6ec8b68"}>
                Blogs
              </Link>
            </li> */}

          </ul>
          <Button 
            class="hidden lg:inline inline-flex mx-auto lg:mx-0 items-center 
    font-semibold border border-solid border-blue-400 py-3 px-5 focus:outline-none focus:ring-4 
    focus:ring-blue-300 hover:bg-blue-700 rounded-full text-gradient
      mt-4 lg:mt-0"
      data-tally-open="mOAaPK" 
          >
            Schedule Demo
          </Button>
          </div>
        {/* </div> */}
      </nav>
    </header>
  );
}

export default Header;
