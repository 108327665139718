import { Box, Button } from "@mui/material";
import CustomReactPlayer from "./CustomReactPlayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import zIndex from "@mui/material/styles/zIndex";

function HeroSection() {
  return (
    <section class="template6Hero">
      <div class="mx-auto md:w-4/5  pt-12 hero-heading">
        <div class="text-center pt-4 md:mt-8 mx-auto w-[90%] md:w-4/5 ">
          <h1
            class="text-3xl font-bold leading-[50px] md:leading-[60px] lg:leading-[80px] inline
text-iso-gray-dark
tracking-[-1.4px] sm:text-4xl md:text-6xl lg:text-7xl Inter"
          >
            Claims made Easier and Faster&nbsp;
          </h1>
          <span
            class="text-3xl text-gradient font-bold 
tracking-[-1.4px] sm:text-4xl md:text-6xl lg:text-7xl Inter"
          >
            with AI
          </span>
          <p class="text-sm sm:block text-iso-gray w-4/5 sm:text-lg md:text-xl pt-2 mt-4 font-normal text-center tracking-loose mx-auto Inter">
            Streamline Claim Resolution with AI Assistant: A Game-Changing
            Solution for Efficient and Timely Claims Processing
          </p>
          <div class="flex flex-row mx-auto justify-center pt-7 mt-4 ">
            <Button
              class=" lg:inline inline-flex mx-auto lg:mx-0 items-center 
    font-semibold border border-solid border-blue-400 py-3 px-5 focus:outline-none focus:ring-4 
    focus:ring-blue-300 hover:bg-blue-700 rounded-full text-gradient
      mt-4 lg:mt-0"
      data-tally-open="mOAaPK" 
            >
              Schedule Demo
              <FontAwesomeIcon
                style={{ color: "blue !important", fontSize: "medium" }}
                icon={"arrow-right"}
              ></FontAwesomeIcon>
            </Button>
          </div>
        </div>
      </div>
      <Box
        class=" mx-auto rounded-2xl  hero-video"
        sx={{ paddingBottom: "10rem" }}
      >
        <CustomReactPlayer
          videoSrc={"https://neatprocesspubliccontent.s3.amazonaws.com/website/Video_for_AI_assistant_resized_for_main-1.mp4"}
          className=" rounded-2xl mx-auto object-cover h-full heading-video"
          sx={{ width: "80vw" }}
        />
      </Box>
    </section>
  );
}

export default HeroSection;
