import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer class="bg-white rounded-lg shadow dark:bg-gray-800  m-6 relative ">
      <div class="w-full mx-auto max-w-screen-xl text-center p-4 md:flex md:items-center md:justify-between">
        <span class="text-sm text-gray-500 dark:text-gray-400">
          © 2024{" "}
          <a href="/" class="hover:underline">
            Neatprocess
          </a>
          . All Rights Reserved.
        </span>
        <ul class="flex flex-wrap items-center justify-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="/terms/privacy-policy" class="hover:underline me-4 md:me-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/terms/security-policy" class="hover:underline me-4 md:me-6">
              Security Policy
            </a>
          </li>
          <li>
            <a href="/terms/gdpr-policy" class="hover:underline me-4 md:me-6">
              GDPR
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
