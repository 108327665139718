import { Box } from "@mui/material";

function Features() {
  return (
    <section class="bg-white" id="feature-section">
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Box className="text-center " sx={{ width: "80%", marginTop: "5rem" }}>
          <span
            class="badgeBoxShadow uppercase rounded-2xl text-sm lg:text-base font-medium leading-loose 
tracking-widest px-4 py-2 badgeGradientText text-transparent bg-clip-text  Inter mb-2"
          >
            Major Features
          </span>
          <h2 class="font-bold text-2xl md:text-3xl lg:text-4xl text-iso-gray-dark pt-3 mt-6  Inter">
            Reduce Claim <span class="text-gradient">Turn Around Time</span> and{" "}
            <span class="text-gradient">UnHappy Customer</span>
          </h2>
        </Box>
      </Box>
      <div class=" pt-5 w-6/6 sm:w-4/5 mx-auto  ">
        <div
          class=" md:grid md:gap-8 md:grid-cols-2 items-center"
        
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1
              class="w-10/12 sm:w-12/12 text-center sm:text-left text-1xl md:text-2xl lg:text-2xl  pt-0  mb-4 font-semibold text-gray-900 dark:text-zinc-50 Inter mx-auto sm:mx-0"
              style={{ color: "black" }}
            >
              Summarize Claims and Perform Actions
            </h1>
            <p class="hidden sm:flex w-6/6 mb-8 leading-relaxed mt-4 text-[#475467] dark:text-zinc-500 text-lg   Inter">
              You can quickly navigate through your claim related tasks and
              bring the claim towards resolution
            </p>
            <svg
              aria-hidden="true"
              focusable="false"
              style={{ width: "0px", height: "0px", position: "absolute" }}
            >
              <linearGradient id="gradient1" x2="1" y2="1">
                <stop offset="0%" stop-color="#7A76FC"></stop>
                <stop offset="100%" stop-color="#61A4EB"></stop>
              </linearGradient>
            </svg>

            <ul class="text-[#475467] flex flex-col flex-wrap space-y-4 text-sm sm:text-lg ml-4 w-11/12 sm:w-6/6">
              <li class="flex items-center space-x-2 grid grid-cols-12  ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="gradientIcon1"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                </svg>
                <span class="undefined undefined col-span-11">
                  Get claim summarization and context within seconds
                </span>
              </li>
              <li class="flex items-center space-x-2 grid grid-cols-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="gradientIcon1"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                </svg>
                <span class="undefined undefined col-span-11">
                  Get AI Suggestions on what actions to be taken next
                </span>
              </li>
              <li class="flex items-center space-x-2 grid grid-cols-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="gradientIcon1"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                </svg>
                <span class="undefined undefined col-span-11">
                  AI Assistant performs those actions upon approval
                </span>
              </li>
            </ul>
          </div>
          <Box
            className="mt-8 sm:mt-2 mx-auto rounded-3xl w-5/6 sm:w-6/6 h-4/6 sm:h-5/6 y-6/6 pt-8 pb-0  featureGradient1"
            sx={{ overflow: "hidden" }}
          >
            <div class="inline" data-isoeditableelem="true" draggable="true">
              <img
                src="img/initial/aiagent.png"
                class="h-6/6 w-5/6 m-auto rounded-3xl my-4 mb-0"
              />
            </div>
          </Box>
        </div>
      </div>
      <div class=" pt-10 sm:pt-5 w-6/6 sm:w-4/5 mx-auto  ">
        <div class="md:grid flex flex-col-reverse md:gap-8 md:grid-cols-2 items-center">
          <div
            class=" mt-8 sm:mt-2 mx-auto rounded-3xl w-5/6 md:w-6/6 h-5/6 y-6/6  lg:pt-8 lg:pb-8  featureGradient1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div class="inline" data-isoeditableelem="true" draggable="true">
              <img
                src="https://res.cloudinary.com/dnzjbmzag/image/upload/v1696014460/dashboardChart.png"
                class="h-6/6 w-5/6 m-auto rounded-3xl my-8 "
              />
            </div>
          </div>
          <div
            
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1 style={{ color: "black" }} class="text-center sm:text-left w-10/12 text-1xl md:text-2xl lg:text-2xl leading-tight pt-0 tracking-tight mb-4 font-semibold text-gray-900 dark:text-zinc-50 Inter mx-auto sm:mx-0">
              Enhance Agent Productivity and Elevate Customer Satisfaction
            </h1>
            <p class="hidden sm:flex w-6/6 mb-8 leading-relaxed mt-4 text-[#475467] dark:text-zinc-500 text-lg Inter">
              Utilize dynamic reporting to identify and eliminate
              inefficiencies, while accessing interactive charts for in-depth
              analysis
            </p>
            <ul
              class="text-[#475467] flex flex-col flex-wrap space-y-4 text-sm  sm:text-lg ml-4 w-11/12 sm:w-6/6"
              style={{ alignItems: "left" }}
            >
              <li class="flex items-center space-x-2 grid grid-cols-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="gradientIcon1"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                </svg>
                <span class="undefined undefined col-span-11">
                  Access tools to streamline agent productivity
                </span>
              </li>
              <li class="flex items-center space-x-2 grid grid-cols-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="gradientIcon1"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                </svg>
                <span class="undefined undefined col-span-11">
                  Receive AI-driven insights to prioritize tasks effectively
                </span>
              </li>
              <li class="flex items-center space-x-2 grid grid-cols-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="gradientIcon1"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                </svg>
                <span class="undefined undefined col-span-11">
                  Utilize interactive charts and graphs for deeper data
                  exploration and analysis
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
