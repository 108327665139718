
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import HomePage from "./Home";
import BlogsPage from "./blogsPage";
import Terms from "./terms";



const App = () => {
  return (
    <Routes>
    <Route exact path="/" element={<HomePage />} />
    {/* <Route path="/blogs/:blogId" element={<BlogsPage />} /> */}
    <Route path="/terms/*" element={<Terms />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
  );
};

export default App;
