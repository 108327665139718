function FaqSection() {
  return (
    <section class="sm:mb-12 w-4/5  mx-auto">
      <div class="bg-white" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <div class=" text-center mt-14">
          <span
            class="badgeBoxShadow uppercase rounded-2xl text-[#6A65FF] text-base font-medium 
leading-loose 
tracking-widest px-4 py-2 badgeGradientText text-transparent bg-clip-text Inter"
          >
            FAQ
          </span>
          <h2 class="font-bold text-2xl md:text-3xl lg:text-4xl text-iso-gray-dark pt-3 mt-6  Inter">
            Frequently Asked{" "}
            <span
              class=" font-bold text-2xl md:text-3xl lg:text-4xl
bg-gradient-to-r from-[#7A76FC] to-[#61A4EB] 
text-transparent bg-clip-text  Inter"
            >
              Questions
            </span>
          </h2>

          <p class="font-inter font-normal text-md sm:text-lg  text-[#314359] pt-4 mt-2 Inter">
            Everything you need to know about the product.
          </p>
        </div>
        <div class="mt-12 text-lg w-5/5 sm:w-8/12">
          <div class="pb-12 flex flex-col space-y-6">
            <details class="bg-white rounded-3xl mb-1 faqBoxShadow6" open="">
              <summary
                class="font-bold text-[#182430] py-6 px-5 sm:px-10 text-md sm:text-xl dark:text-grey-200 
cursor-pointer"
              >
                How do I get started?
              </summary>
              <div>
                <p class="text-[#314359] px-5 sm:px-10 pb-6 text-base Inter">
                  {" "}
                  You're welcome to arrange a demonstration with our team and
                  commence your journey on the platform.
                </p>
              </div>
            </details>
            <details class="bg-white rounded-3xl mb-1 faqBoxShadow6" open="">
              <summary
                class="font-bold text-[#182430] py-6 px-5 sm:px-10 text-md sm:text-xl dark:text-grey-200  
cursor-pointer"
              >
                Can I use just the AI Assistant for my own platform?
              </summary>
              <div>
                <p class="text-[#314359] px-5 sm:px-10 pb-6 text-base Inter">
                  {" "}
                  We're currently developing our AI Assistant to seamlessly
                  integrate with any platform. Reach out to us with your
                  specifics, and we'll promptly respond once it's prepared.{" "}
                </p>
              </div>
            </details>

            <details class="bg-white rounded-3xl mb-1 faqBoxShadow6" open="">
              <summary
                class="font-bold text-[#182430] py-6 px-5 sm:px-10 text-md sm:text-xl dark:text-grey-200 
cursor-pointer"
              >
                Can I customize it for my needs?
              </summary>
              <div>
                <p class="text-[#314359] px-5 sm:px-10 pb-6 text-base Inter">
                  {" "}
                  Absolutely, it's incredibly customizable. Just let us know the
                  features you require, and we'll integrate them seamlessly into
                  the platform.
                </p>
              </div>
            </details>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqSection;
