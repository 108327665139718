import {
  faList,
  faRobot,
  faTag,
  faTree,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext } from "@mui/lab";
import {
  AppBar,
  Box,
  Card,
  Grid,
  Icon,
  LinearProgress,
  linearProgressClasses,
  Tab,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import CustomReactPlayer from "./CustomReactPlayer";
import { styled } from "@mui/material/styles";

const componentMapping = {
  main: [
    {
      id: 1,
      heading: "AI Agent",
      description:
        "Guides users, recommends fields, prompts actions, and suggests notifications",
      link: "https://neatprocesspubliccontent.s3.amazonaws.com/website/Video_for_Ai_assistant-1.mp4",
      icon: (
        <lottie-player
          src="https://lottie.host/56b2c8f6-5525-4c17-96be-87ad65de28de/LL0Sw3bEMT.json"
          background="transparent"
          speed="1"
          style={{
            width: "1rem",
            marginRight:"0",
            paddingRight:"0",
            height:"2rem"
          }}
          loop
          autoplay
          direction="1"
          mode="normal"
        />
      ),
    },
    {
      id: 2,
      heading: "AI Recommended Claims",
      description:
        "Instantly identify pending tasks and required fields in a list view",
      link: "https://neatprocesspubliccontent.s3.amazonaws.com/website/Video_for_recommended_claims-1.mp4",
      icon: <FontAwesomeIcon icon={faList} className="feature-icons" />,
    },
    {
      id: 3,
      heading: "Auto Claim Tagging",
      description:
        "AI given tags to categorise claims into comprehensive buckets",
      link: "https://neatprocesspubliccontent.s3.amazonaws.com/website/Video_for_Tags-1.mp4",
      icon: <FontAwesomeIcon icon={faTag} className="feature-icons" />,
    },
  ],
  analytics: [
    {
      id: 1,
      heading: "Data Bot",
      description:
        "Get tailored data based on your needs and dynamically visualizations in real-time",
      link: "https://neatprocesspubliccontent.s3.amazonaws.com/website/Video_for_data_bot.mp4",
      icon: (
        <lottie-player
          src="https://lottie.host/56b2c8f6-5525-4c17-96be-87ad65de28de/LL0Sw3bEMT.json"
          background="transparent"
          speed="1"
          style={{
            width: "1rem",
            marginRight:"0",
            paddingRight:"0",
            height:"2rem"
          }}
          loop
          autoplay
          direction="1"
          mode="normal"
        />
      ),
    },
    {
      id: 2,
      heading: "Data Breakdown",
      description:
        "Get simplified categorisation of data in a tree structure",
      link: "https://neatprocesspubliccontent.s3.amazonaws.com/website/Video_for_Tree_brakdown.mp4",
      icon: <FontAwesomeIcon icon={faTree} className="feature-icons" />,
    },
  ],
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 5,

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: "linear-gradient(#e66465, #9198e5);",
    backgroundImage: "linear-gradient(92.57deg, #7a76fc 31.91%, #61a4eb 88%)",
  },
}));

function ComponentsSection() {
  const [selectedComponent, setSelectedComponent] = useState(
    componentMapping["main"][0]
  );
  const [tabValue, setTabValue] = useState("main");
  const handleSetTabValue = (event, newValue) => {
    setSelectedComponent(componentMapping[newValue][0]);
    setTabValue(newValue);
  };

  const handleComponentSelect = (mapping, index) => {
    setSelectedComponent(componentMapping[mapping][index]);
  };

  const handleNextVideo = (mapping, index) => {
    if (index >= componentMapping[mapping].length) {
      index = 0;
    }
    setSelectedComponent(componentMapping[mapping][index]);
  };

  const [progress, setProgress] = useState(0);

  return (
    <section id={"component-section"} >
    <Box >
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Box className="text-center " sx={{ width: "80%" }}>
          <span
            class="badgeBoxShadow capitalize rounded-2xl text-xs sm:text-lg font-medium leading-loose 
tracking-widest px-4 py-2 badgeGradientText text-transparent bg-clip-text  Inter "
          >
            Introducing Neatprocess
          </span>
          <h2 class="font-bold text-2xl md:text-3xl lg:text-4xl text-iso-gray-dark pt-3 mt-6  Inter">
            Empowering Claim Resolution with{" "}
            <span class="text-gradient">Robust Assistance</span> and{" "}
            <span class="text-gradient">Actionable Reporting</span>
          </h2>

          <p class="font-normal text-md sm:text-lg  text-iso-gray-light pt-4 mt-0  Inter">
            Enhance Claims Efficiency, Minimize Turnaround Time and
            Streamline Interdepartmental Communication
          </p>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            // width: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={"w-11/12 sm:w-5/6 md:w-9/12"}
        >
          <TabContext
            value={tabValue}
            sx={{ display: "flex", justifyContent: "center" }}
            
          >
            <AppBar
              position="static"
              style={{
                backgroundColor: "white",
                display: "flex",
                borderRadius: "10px",
                margin: "3rem 2rem 1rem 2rem",
                // width: "50%",
              }}
              className={"w-5/6 sm:w-3/6 md:w-3/6"}
            >
              <Tabs
                orientation={"horizontal"}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ minHeight: "0rem" }}
              >
                <Tab
                  label="Claim Management"
                  value={"main"}
                  style={{
                    fontSize: "larger",
                    fontFamily: "math",
                    color: tabValue == "main" ? "white" : "grey",
                    minWidth: "50%",
                    minHeight: "1rem !important",
                    height: "1rem",
                  }}
                  className={
                    tabValue == "main"
                      ? "tab-selected custom-tab"
                      : " custom-tab"
                  }
                />
                <Tab
                  label="Analytics Dashboard"
                  value={"analytics"}
                  style={{
                    fontSize: "larger",
                    fontFamily: "math",
                    color: tabValue == "analytics" ? "white" : "grey",
                    minWidth: "50%",
                    minHeight: "1rem !important",
                    height: "1rem",
                  }}
                  className={
                    tabValue == "analytics"
                      ? "tab-selected custom-tab"
                      : "custom-tab"
                  }
                />
              </Tabs>
            </AppBar>
          </TabContext>
          <Card
            sx={{
              marginBottom: "1rem",
              // border: "0.5px solid lightgrey",
              borderRadius: "10px",
              width:"100%"
            }}
          >
            <Box className="grid grid-cols-1  lg:grid-cols-3  ">
              <Box
                sx={{
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "space-evenly",
                  paddingTop: "0.5rem",
                  paddingLeft: "0.5rem",
                }}
                className={"grid grid-cols-1  md:grid-cols-1 gap-2 md:gap-1 col-span-1"}
              >
                {componentMapping[tabValue].map((component, index) => {
                  return (
                    <Card
                      sx={{
                        padding: "1rem",
                        // margin: "1rem",

                        border: component.heading != selectedComponent.heading?"":"0.5px solid lightgrey",
                        // display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow: "0px 3px 5px 2px lightgrey",
                        },
                        paddingRight: "0.5rem",
                        borderRadius: "10px",
                        // marginBottom:"0.5rem"
                        
                      }}
                      key={index}
                      onClick={() => handleComponentSelect(tabValue, index)}
                      className={"m-1 w-11/12 sm:m-auto sm:ml-4 "}
                    >
                      <Box  className={"flex"}>
                        <Box
                          sx={{
                            
                            alignItems: "center",
                            marginRight: "1rem",
                            
                          }}
                          
                        >
                          {component.icon}
                        </Box>
                        <Box className={""}>
                          <Box sx={{ fontSize: "medium", fontWeight: "600" }}>
                            {component.heading}
                          </Box>
                          <Box
                            sx={{
                              fontSize: "small",
                              color: "grey",
                              fontWeight: "200",
                            }}
                            className={"hidden md:flex "}
                          >
                            {component.description}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: "0.5rem",
                          marginBottom: "-0.5rem",
                          alignSelf:"center"
                        }}
                      >
                        {component.heading == selectedComponent.heading && (
                          <BorderLinearProgress
                            color="info"
                            variant="determinate"
                            value={progress}
                          />
                        )}
                      </Box>
                    </Card>
                  );
                })}
              </Box>
              <Box
                item
                xs={8}
                sx={{
                  padding: "1rem",
                  // display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}

                className={"grid grid-cols-1 gap-2 md:gap-2 col-span-2"}
              >
                <CustomReactPlayer
                  videoSrc={selectedComponent["link"]}
                  sx={{
                    width: "100%",
                  }}
                  className={"feature-video  "}
                  setProgress={setProgress}
                  nextItem={() =>
                    handleNextVideo(tabValue, selectedComponent["id"])
                  }

                      height={"auto"}
                  autoplay={1}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
    </section>
  );
}

export default ComponentsSection;
